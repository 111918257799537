
.search-bar-content {
    .header {
        color: #ffffff;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        font-size: 1rem;
        overscroll-behavior-inline: contain;
        padding: 0px 60px;
        @media (min-width: 990px) {
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
        }

        .login-cnt {
            background-color: #D0E057;
            padding: 1.6rem;
            font-family: "nexa-light";
            font-weight: 700;
            p {
                color: #192F41;
                margin: 0;
                font-size: 18px;
                font-style: normal;
                line-height: 28px;
                font-family: "nexa-light";
                font-weight: 700;
                a {
                    color: #192F41;
                }
            }
            .number{
                font-family: "nexa-bold";
                font-weight: 900;
                font-size:clamp(1.8rem, 3vw, 2rem);
            }
        }

        .header-item {
            align-items: flex-end;
            display: flex;
            justify-content: center;
            margin-bottom: 0.5rem;
            @media (min-width: 992px){
                align-items: center;
                flex-direction: row;
                margin-bottom: 0;
            }
            .search {
                display: flex;
                margin-right: 20px;
                position: relative;
                width: 100%;

                input {
                    border: 1px solid #D0CFCA;
                    border-radius: 60px 0 0 60px;
                    border-right: none;
                    font-size: 14px;
                    outline: none;
                    padding: 18px 15px;
                    width: 100%;
                    color: #0009;
                    min-width: 220px;
                    font-family: 'nexa-light';
                    font-weight: 400;
                }

                button {
                    border-top: 1px solid #D0CFCA;
                    border-bottom: 1px solid #D0CFCA;
                    border-right: 1px solid #D0CFCA;
                    border-left: 0;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    border-top-right-radius: 60px!important;
                    border-bottom-right-radius: 60px!important;
                    background-color: #ffffff;
                    padding: 0 15px 0 0;
                    align-items: center;
                }
            }
        }
    }

    .bg-primary {
        background: #8EBDF5!important;
    }

    .login-cnt-out {
        background-color: #D0E057;
        padding: 1rem;
        text-align: center;
        font-family: "nexa-light";
        font-weight: 700;

        p {
            color: #192F41;
            margin: 0;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
            font-family: "nexa-light";
            font-weight: 700;
            a {
                color: #192F41;
            }
        }
        .number{
            font-family: "nexa-bold";
            font-weight: 900;
        }
    }

    .pointer {
        cursor: pointer;
    }
}


