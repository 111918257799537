footer {
    background-color: var(--blue-color);
    color: var(--white-color);
    padding: 2.4rem 0.85rem;
    font-family: "nexa-regular";    
    font-size: 2rem;

    .title-3 {
        color: var(--sky-blue-color);
        br {
            display: none;
            @include breakpoint(md) {
                display: block;
            }
        }

        text-decoration: none;
        img {
            margin-left: 2rem;
            position: absolute;
            width: 50px;
            height: 50px;
            @include breakpoint(md) {
                width: 40px;
                height: 40px;
            }
          }
      
          &:hover img {
            animation: myAnim 0.8s ease 0s 1 normal both;
          }
      
          @keyframes myAnim {
            0% {
              animation-timing-function: ease-in;
              opacity: 1;
              transform: translateX(0px);
            }
          
            24% {
              opacity: 1;
            }
          
            40% {
              animation-timing-function: ease-in;
              transform: translateX(7px);
            }
          
            65% {
              animation-timing-function: ease-in;
              transform: translateX(10px);
            }
          
            82% {
              animation-timing-function: ease-in;
              transform: translateX(13px);
            }
          
            93% {
              animation-timing-function: ease-in;
              transform: translateX(15px);
            }
          
            25%,
            55%,
            75%,
            87%,
            98% {
              animation-timing-function: ease-out;
              transform: translateX(15px);
            }
          
            100% {
              animation-timing-function: ease-out;
              opacity: 1;
              transform: translateX(15px);
            }
          }
      
          &:not(:hover) img {
            animation: bounceBack 0.8s ease 0s 1 normal both;
          }
      
          @keyframes bounceBack {
            0% {
              animation-timing-function: ease-in;
              opacity: 1;
              transform: translateX(15px);
            }
          
            24% {
              opacity: 1;
            }
          
            40% {
              animation-timing-function: ease-in;
              transform: translateX(-8px);
            }
          
            65% {
              animation-timing-function: ease-in;
              transform: translateX(-5px);
            }
          
            82% {
              animation-timing-function: ease-in;
              transform: translateX(-2px);
            }
          
            93% {
              animation-timing-function: ease-in;
              transform: translateX(0px);
            }
          
            25%,
            55%,
            75%,
            87%,
            98% {
              animation-timing-function: ease-out;
              transform: translateX(0px);
            }
          
            100% {
              animation-timing-function: ease-out;
              opacity: 1;
              transform: translateX(0px);
            }
          }
      
          @include breakpoint(md) {
            font-size: 2.8rem;
            line-height: 33px;
          }
      
          @media screen and (max-width: 767px) {
            padding-right: 11rem;
            img {
              position: relative;
              right: -130px;
              bottom: 19px;
            }
          }
      
          @media screen and (max-width: 485px) {
            padding-right: 18rem;
            img {
              position: relative;
              right: -161px;
              bottom: 36px;
            }
          }
      
          @media screen and (max-width: 425px) {
            padding-right: 13rem;
          }
      
          @media screen and (max-width: 414px) {
            padding-right: 11rem;
            img {
              position: relative;
              right: -137px;
              bottom: 45px;
            }
          }
      
          @media screen and (max-width: 409px) {
            padding-right: 10rem;
            img {
              right: -140px;
              bottom: 45px;
      
            }
          }
      
          @media screen and (max-width: 396px) {
            padding-right: 8rem;
            img {
              right: -125px;
              bottom: 35px;
      
            }
          }
          
          @media screen and (max-width: 376px) {
            padding-right: 6rem;
            img {
              right: -125px;
              bottom: 35px;
      
            }
          }
      
          @media screen and (max-width: 320px) {
            padding-right: 3rem;
            img {
              right: -140px;
              bottom: 28px;
      
            }
          }
    }

    a {
        color: var(--white-color);
    }
    .line{
        border-top: 1px solid var(--white-color);
        margin: 40px 0 10px;
    } 
    .footer-body{
        display: flex;
        padding-bottom: 3rem;
    }
    .footer-navigation{
        font-family: "nexa-light";
        .weekly_link{
            text-decoration: none;
            font-weight: 700;
        }
    }
    
    a.social-link {
        display: inline;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 45px;
        padding: 25px 30px;
        vertical-align: middle;

        &.linkedin {
        background-image: url(../images/icon-linkedin-white-border.svg);
            &:hover {
                background-image: url(../images/logo-linkedin-white.svg);
            }
        }
        &.instagram {
            background-image: url(../images/icon-instagram-white-border.svg);
            &:hover {
                background-image: url(../images/logo-instagram-white.svg);
            }
        }
    }

    .footer-navigation-box {
        a {
            padding: 3px 0;
        }
    }
}