
.coupons-table-content {
    padding: 32px 60px;
    @media (max-width: 990px) {
        padding: 16px;
    }
    background-color: #ECEBE5;
    .btn-filter {
        border-radius: 4px;
        background: #F0EDF3;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
        color: #192F41;
        font-size: 14px;
        font-weight: 400;
        border-color: transparent;
        display: flex;
        padding: 14px 16px;
        align-items: center;
        gap: 6px;
        font-family: "nexa-light";
        margin-bottom: -30px;
        @media (max-width: 990px) {
            margin-bottom: 14px;
        }
    }
    .filter-cnt {
        .announce-cnt {
            border-bottom: 2px solid#192F41;
            @media (max-width: 990px) {
                height: 45px;
                margin: 0 auto;
                width: 95%;
            }
            h5 {
                margin-bottom: -12px;
                color: #192F41;
                text-align: center;
                font-size: 18px;
                font-weight: 700;
                font-family: "nexa-bold";
            }
        }
    }

    .nav-tabs {
        width: 60%;
        margin: 0 auto;
        @media (max-width: 990px) {
            width: 98%;
        }
    }

    .nav-link {
        font-family: "nexa-light";
        font-size: 18px;
        color: #757575;
        border-color: transparent !important;
        border-bottom: 1px solid #757575 !important;
        font-weight: 700;
    }
    .nav-link:hover {
        color: #757575;
    }

    .nav-link.active {
        color: #192F41 !important;
        background-color: transparent !important;
        border-bottom: 2px solid #192F41 !important;
    }
}


