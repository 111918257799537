.card-coupon-content {
    background-color: #fff;
    padding: 1rem;
    height: 100%;
    .img-cnt {
        height: 212px;
        padding: 5px;
    }
    .detail-cnt {
        .small{
            font-family: "nexa-light";
            font-weight: 400;
            font-size: 1.4rem;
            color: var(--dark-gray-color);
        }
        h2 {
            margin: 5px 0;
            font-family: "nexa-bold";
            font-weight: 900;
            color: var(--blue-color);
        }
        p {
            margin: 0;
            font-family: "nexa-light";
            font-weight: 700;

        }
        a {
            line-height: 2.7rem;
            font-family: "nexa-light";
            color: var(--dark-gray-color);
        }
        h3{
            font-family: "nexa-light";
            font-weight: 700;
        }
        .button-blue {
            font-size: 1.5rem;
            align-items: center;
            display: flex;
            justify-content: center;
            gap: 5px;
            font-family: "nexa-light";
        }
        .button-blue-white {
            font-size: 1.5rem;
            align-items: center;
            display: flex;
            justify-content: center;
            gap: 5px;
            font-family: "nexa-light";
        }
        .button-red-outline {
            display: inline-block;
            outline: none;
            cursor: pointer;
            font-size: 1.5rem;
            line-height: 1;
            border-radius: 500px;
            transition-property: background-color,border-color,color,box-shadow,filter;
            transition-duration: .3s;
            border: 1px solid transparent;
            font-family: "nexa-light";
            white-space: normal;
            font-weight: 400;
            text-align: center;
            color: #AA2213;
            background-color: transparent;
            height: 42px;
            width: clamp(12.4rem, 10vw, 15.1rem);
            &:hover{
                background-color: #89B9F5;
                color: #192F41;
            }
        }
    }
}


