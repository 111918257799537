@import "_breakpoints.scss";

p {
  font-size: 1.6rem;
  line-height: 2.2rem;
}

.p-modal {
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-family: "nexa-bold";
}

// H1 heading d-75px m-55px
.title {
  color: var(--blue-color);
  font-weight: 900;
  font-size: clamp(5.5rem, 10vw, 7.5rem);
  line-height: clamp(5.9rem, 1rem + 9vw, 8rem);
}

// H1 heading d-75px m-48px
.title-mb {
  color: var(--blue-color);
  font-weight: 900;
  font-size: clamp(4.8rem, 10vw, 7.5rem);
  line-height: clamp(5.2rem, 1rem + 9vw, 8rem);
}

//H2 heading d-48px m-32px
.title-2 {
  color: var(--blue-color);
  font-family: "nexa-black";
  font-size: clamp(3.2rem, 10vw, 4.8rem);
  line-height: clamp(5.2rem, 1rem + 7vw, 6.4rem);
}

//H2 heading d-60px m-48
.title-2-1 {
  color: var(--white-color);
  font-weight: 900;
  font-size: clamp(3.2rem, 10vw, 4.8rem);
  line-height: clamp(5.2rem, 1rem + 7vw, 6.4rem);
}

//H3 heading d-50px m-35px
.title-3 {
  //color: var(--blue-color);
  font-weight: 900;
  font-family: "nexa-black";
  font-size: clamp(2.8rem, 5vw, 3.2rem);
  line-height: clamp(3.9rem, 9vw, 5.4rem);
}

//H3 heading 42px
.title-3-1 {
  color: var(--blue-color);
  font-weight: 900;
  font-family: "nexa-black";
  font-size: clamp(3.8rem, 5vw, 4.2rem);
  line-height: clamp(3.9rem, 9vw, 5.4rem);
}

//H3 heading 32px
.title-3-2 {
  color: var(--blue-color);
  font-weight: 900;
  font-family: "nexa-black";
  font-size: clamp(2.8rem, 5vw, 3.2rem);
  line-height: clamp(3.9rem, 9vw, 5.4rem);
}

//H3 heading 32px
.title-3-2-2 {
  color: var(--red-fire-color);
  font-weight: 900;
  font-family: "nexa-black";
  font-size: clamp(2.8rem, 5vw, 3.2rem);
  line-height: clamp(3.9rem, 9vw, 5.4rem);
}


//H4 heading 25px
.title-4 {
  color: var(--blue-color);
  font-weight: 900;
  font-size: clamp(1.6rem, 5vw, 2.4rem);
  line-height: 2.9rem;
}

//H4 heading 25px
.title-4-l {
  color: var(--blue-color);
  font-weight: 900;
  font-size: clamp(2.3rem, 5vw, 2.5rem);
  line-height: 2.9rem;
}

//H4 heading 25px
.title-4-2 {
  color: var(--white-color);
  font-weight: 900;
  font-size: clamp(1.6rem, 5vw, 2.4rem);
  line-height: 2.9rem;
}

//H4 heading 25px m-18px
.title-4-mb {
  color: var(--blue-color);
  font-weight: 900;
  font-size: clamp(1.8rem, 4vw, 2.5rem);
  line-height: clamp(2.7rem, 5vw, 2.9rem);
}

//large-body 18px
.large-body {
  font-family: "Nexa-Bold";
  font-weight: 700;
  font-size: clamp(1.6rem, 3vw, 2.4rem);
  line-height: clamp(2.4rem, 3vw, 3.6rem);
}

//large-text 18px mb-16
.large-text {
  font-weight: 900;
  font-size: clamp(1.6rem, 3vw, 1.8rem);
  line-height: clamp(2.2rem, 10vw, 2.7rem);
}

//medium-text 20px
.medium-text {
  //color: var(--blue-color);
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.4rem;
}

//upper-medium-text 15px
.upper-medium-text {
  color: var(--blue-color);
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
}

//mediun-text-link 16px
/* .medium-text-link {
  color: var(--blue-color);
  font-weight: 900;
  font-size: 1.6rem;
  line-height: 2.2rem;
} */

//small-text 14px
.small-text {
  color: var(--blue-color);
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.1rem;
}

//small-text 14px bm-12px
.small-text-mb {
  color: var(--blue-color);
  font-weight: 400;
  font-size: clamp(1.2rem, 3vw, 1.4rem);
  line-height: clamp(1.6rem, 3vw, 1.7rem);
}

//small-text-link 14px
.small-text-link {
  color: var(--blue-color);
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.7rem;
  &:hover {
    text-decoration: underline;
  }
}
/* Links */
.underlined-link {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  &:hover {
    text-decoration: none;
  }
}
.no-underlined-link {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
  }
}
.reverse {
  color: var(--antique-white-color);
}

.bold {
  font-weight: var(--fw-bold); 
}

// Font Weight 900
.black {
  font-weight: var(--fw-black); 
}