//breack points map
$breakpoints: (
"xs": "max-width: 575.98px",
"sm": "max-width:767.98px",
"md": "max-width:991.98px",
"lg": "max-width:1199.98px",
"xl": "max-width:1399.98px",
"m-md": "min-width: 991.98px",
"xxl": "min-width: 1400px",
);
@mixin breakpoint($user-value) {
  $my-map: map-get($breakpoints, $user-value);
  @if $my-map {
    @media (#{$my-map}) {
      @content;
    }
  }@else {
    @error "opps, seens like the brackpoints #{$user-value} isn't definde yet"
  }  
};