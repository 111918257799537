.box-content {
  border: 1px solid #B9B9B9;
  background: #FFF;
  display: flex;
  padding: 2.4rem;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  font-family: "nexa-black";

  @media (max-width: 990px) {
    padding: 1.6rem;
  }


  .img-cnt {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .detail-cnt {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    width: 100%;
    
    p {
      margin-bottom: 0;
    }
  }
}


