@import "_variables.scss";
@import "_scaffolding.scss";
@import "_breakpoints.scss";
@import "_typography.scss";
@import "_topOffers.scss";
@import "_header.scss";
@import "_footer.scss";
@import "_hero.scss";

@font-face {
  font-family: "nexa-black";   /*Can be any text*/
  src: local("nexa-black"),
    url("./../fonts/nexa-black.otf") format("truetype");
}
@font-face {
  font-family: "nexa-blackitalic";   /*Can be any text*/
  src: local("nexa-blackitalic"),
    url("./../fonts/nexa-blackitalic.otf") format("truetype");
}
@font-face {
  font-family: "nexa-bold";   /*Can be any text*/
  src: local("nexa-bold"),
    url("./../fonts/nexa-bold.otf") format("truetype");
}
@font-face {
  font-family: "nexa-bolditalic";   /*Can be any text*/
  src: local("nexa-bolditalic"),
    url("./../fonts/nexa-bolditalic.otf") format("truetype");
}
@font-face {
  font-family: "nexa-book";   /*Can be any text*/
  src: local("nexa-book"),
    url("./../fonts/nexa-book.otf") format("truetype");
}
@font-face {
  font-family: "nexa-bookitalic";   /*Can be any text*/
  src: local("nexa-bookitalic"),
    url("./../fonts/nexa-bookitalic.otf") format("truetype");
}
@font-face {
  font-family: "nexa-extrabold";   /*Can be any text*/
  src: local("nexa-extrabold"),
    url("./../fonts/nexa-extrabold.otf") format("truetype");
}
@font-face {
  font-family: "nexa-extralight";   /*Can be any text*/
  src: local("nexa-extralight"),
    url("./../fonts/nexa-extralight.otf") format("truetype");
}
@font-face {
  font-family: "nexa-heavy";   /*Can be any text*/
  src: local("nexa-heavyt"),
    url("./../fonts/nexa-heavy.otf") format("truetype");
}
@font-face {
  font-family: "nexa-light";   /*Can be any text*/
  src: local("nexa-light"),
    url("./../fonts/nexa-light.otf") format("truetype");
}
@font-face {
  font-family: "nexa-lightitalic";   /*Can be any text*/
  src: local("nexa-lightitalic"),
    url("./../fonts/nexa-lightitalic.otf") format("truetype");
}
@font-face {
  font-family: "nexa-regular";   /*Can be any text*/
  src: local("nexa-regular"),
    url("./../fonts/nexa-regular.otf") format("truetype");
}
@font-face {
  font-family: "nexa-regularitalic";   /*Can be any text*/
  src: local("nexa-regularitalic"),
    url("./../fonts/nexa-regularitalic.otf") format("truetype");
}

html {
    font-size: 62.5%;
      /* 62.5% equates to 16px w/ rems w/o locking into pixel size for accessibility */
  
}
  
  body {
      margin: 0;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: var(--blue-color);
      //
  }
    
  code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
    
  .no_found_content {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: 7% 10%;
  }

  .button-blue {
    display: inline-block;
    outline: none;
    cursor: pointer;
    font-size: 1.8rem;
    line-height: 1;
    border-radius: 500px;
    transition-property: background-color,border-color,color,box-shadow,filter;
    transition-duration: .5s;
    border: 1px solid transparent;
    font-family: "nexa-light";
    white-space: normal;
    font-weight: 400;
    text-align: center;
    color: #fff;
    background-color:#192F41;
    height: 42px;
    width: clamp(12.4rem, 10vw, 15.1rem);
    &:hover{
      background-color: #89B9F5;
      color: #192F41;
    }
  }

  .button-blue {
    display: inline-block;
    outline: none;
    cursor: pointer;
    font-size: 1.8rem;
    line-height: 1;
    border-radius: 500px;
    transition-property: background-color,border-color,color,box-shadow,filter;
    transition-duration: .5s;
    border: 1px solid transparent;
    font-family: "nexa-light";
    white-space: normal;
    font-weight: 400;
    text-align: center;
    color: #fff;
    background-color:#192F41;
    height: 42px;
    width: clamp(12.4rem, 10vw, 15.1rem);
    &:hover{
      background-color: #89B9F5;
      color: #192F41;
    }
  }

  .button-blue-white {
    display: inline-block;
    outline: none;
    cursor: pointer;
    font-size: 1.8rem;
    line-height: 1;
    border-radius: 500px;
    transition-property: background-color,border-color,color,box-shadow,filter;
    transition-duration: .5s;
    border: 1px solid #192F41;
    font-family: "nexa-light";
    white-space: normal;
    font-weight: 400;
    text-align: center;
    color: #192F41;
    background-color:#ffffff;
    height: 42px;
    width: clamp(12.4rem, 10vw, 15.1rem);
    &:hover{
      background-color: #89B9F5;
      color: #192F41;
    }
  }