.topOffers-content{
  background-color: #192F41;
  color: #ECEBE5;

  .container-epic{
    background-color: #ECEBE5;
    padding: 2.5rem;
    color: var(--blue-color);
    @media (max-width: 990px) {
      background-color: #FFFFFF;
      padding: 15px 10px;
    }
  }

  .img-box {
    max-height: 175px;
    min-height: 170px;
    display: grid;
    align-items: center;
    .img-cnt {
      object-fit: contain;
    }
  }
  .video-box {
    aspect-ratio: 16 / 9;
    width: 100%;
  }
  .box-content {
    min-height: 350px;
    @media (max-width: 990px) {
      padding: 2rem;
    }
  }

  .container-right{
   vertical-align: middle;
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;

   @media (max-width: 990px) {
    padding: 20px 0 0 20px;
    }

    .title-2 {
      margin-bottom: 0.2rem;
      font-family: "Nexa-Black";
      font-size: clamp(2rem, 3vw, 3.2rem);
      line-height: clamp(2.4rem, 3vw, 4.8rem);
    }
   
  }

  .swiper {
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  .swiper-slide {
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .swiper-button {
    display: flex;
    position: absolute;
    z-index: 10;
    cursor: pointer;
    margin-top: -28rem;
    @include breakpoint("sm") {
      display: none;
    }
  }
  .swiper-button svg {
    width: 1.5rem;
    height: 1.5rem;
  }
  .image-swiper-button-prev {
    left: 0px;
    transform: rotate(180deg);
    @include breakpoint("sm") {
      left: 20px;
    }
  }
  .image-swiper-button-next {
    right: 0px;
    @include breakpoint("sm") {
      right: 20px;
    }
  }
  .swiper-button-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  .swiper-pagination {
    //  position: absolute;
    text-align: center;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    background-color: #D0E057;
    width: 136px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    border-radius: 40px;
    gap: 10px;
    @include breakpoint("sm") {
      gap: 5px;
    }
  }
  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: #1E1E1E;
    background: #1E1E1E;
    border-radius: 50%;
  }
  
  .swiper-pagination-bullet-active {
    color: #fff;
    background: #89B9F5;
  }

}