/* silemodal */
.sidenav {
  width: 437px;
  background: var(--antique-white-color);
  position: fixed;
  height: auto;
  padding: 0 19px 15px 19px;
  transition: all 1s;
  left: -100%;
  z-index: 12;
  border: 1px solid #b9b9b9;
  @media (max-width: 750px) {
    width: 337px;
    /* top: 175px; */
  }
  @media (max-width: 280px) {
    width: 237px;
  }

  .close-modal-slide {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 35px;
    @media (max-width: 750px) {
      height: 40px;
    }
    span {
      cursor: pointer;
      margin: 2rem;
    }
  }

  .content-box {
    border: 5px dashed var(--PR_light-blue, #89b9f5);
    background: #fff;
    display: flex;
    padding: 16px 16px;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    @media (max-width: 500px) {
      padding: 14px 10px;
    }
  }

  .title-slide {
    @media (max-width: 550px) {
      letter-spacing: -1px;
    }
  }

  .p-box-slide-firts {
    color: var(--red-fire-color);
    text-align: center;
    font-family: "nexa-black";
    font-size: clamp(3.5rem, 5vw, 6.9rem);
    /* font-weight: 950; */
    line-height: 70px;
    letter-spacing: -0.9px;
    @media (max-width: 990px) {
      /* font-size: clamp(3.5rem, 5vw, 6.9rem); */
      line-height: 42px;
    }
  }

  .p-box-slide-second {
    color: var(--red-fire-color);
    text-align: center;
    font-size: 31px;
    /* font-weight: 950; */
    line-height: 32px;
    align-self: stretch;
    font-family: "nexa-black";
    @media (max-width: 760px) {
      font-size: clamp(1.6rem, 5vw, 2.1rem);
    }
  }

  .red-spam {
    color: var(--PR_Dark-Blue, #aa2213);
    font-weight: 950;
    font-family: "nexa-heavy";
  }

  .btn-slide-cont {
    display: flex;
    padding: 2rem 2rem;
    justify-content: center;
    @media (max-width: 990px) {
      padding: 1rem 2rem;
    }
  }

  .button-blue {
    width: clamp(15.4rem, 10vw, 15.1rem);
  }

  .bold {
    font-size: 16px;
  }

  .underlined-link-slide {
    font-family: "nexa-heavy";
    /* font-weight: 700; */
    font-size: 16px;
    line-height: clamp(2.4rem, 3vw, 3.6rem);
    text-decoration: underline;
    display: flex;
    justify-content: center;
    &:hover {
      text-decoration: none;
    }
  }
  .p-modal {
    @media (max-width: 550px) {
      padding-bottom: 10px;
    }
  }
}

.sidenav.active {
  left: 0;
}

.bot {
  bottom: 0;
}
