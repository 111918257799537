/* .container {
    --bs-gutter-x: 2.5rem !important;
} */

//Background colors
.red-bg {
    background-color: var(--red-color);
}

.green-bg {
    background-color: var(--green-color);
}
  
.blue-bg {
    background-color: var(--blue-color);
}
  
.sky-blue-bg {
    background-color: var(--sky-blue-color);
}  

.antique-white-bg {
    background-color: var(--antique-white-color);
}

.white-bg {
    background-color: var(--white-color);
}


//Colors
.red-color {
    color: var(--red-color);
}
  
.antique-white-color {
    color: var(--antique-white-color);
}

.green-color {
    color: var(--green-color);
}
  
.blue-color {
    color: var(--blue-color);
}
  
.sky-blue-color {
    color : var(--sky-blue-color);
}  

.white-color {
    color: var(--white-color);
}


 //Padding
.px-30 {
    padding-top: 20rem;
    padding-bottom: 20rem;
}

.mb-40 {
    margin-bottom: 4rem;
}

.no-padding {
    padding: 0 !important;
    margin: 0 !important;
 }

//Image
img {
    max-width: 100%;
    max-height: 100%;
}


//Section
section {
    padding: 5rem 0;
    @media (max-width: 990px) {
        padding: 5rem 0.85rem;
      }
    h2 {
        &.title-2 {
            margin-bottom: 1rem;
        }
    }

    h2, h3 {
        text-align: center;
    }
}

.one-line-title {
    -webkit-line-clamp: 1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}