.box-content {
  border: 1px solid #B9B9B9;
  background: #FFF;
  display: flex;
  padding: clamp(0.5rem, -0.75rem + 4vw, 1.5rem);
  flex-direction: column;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;

  .img-cnt {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  h2 {
    color: #1E1E1E;
    font-family: "nexa-black";
    font-size: clamp(1.8rem, 4vw, 2.4rem);
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin: 0;
  }
  
  .detail-cnt {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 1rem;
    width: 100%;
  }
  
  p {
    color: #1E1E1E;
    font-family: "nexa-black";
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
  }
  
}

