/* H3 heading d-50px m-35px */
.weeklyAd-content {
  background-color: #ECEBE5;
  
  .text-p{
    margin-bottom: 20px;
  }
  .box{
    //cursor: pointer; 
    overflow: hidden; 
    @media (max-width: 990px) {
      padding-bottom: 1.6rem;
    }
  }
  .box-content {
    height: 100%;

    .detail-cnt{
      justify-content: center;
      height: 100%;
    }
    
    @media (max-width: 990px) {
      padding: 2.4rem;
    }
  }
  .button-blue {
    width: clamp(20rem, 10vw, 21rem);
  }
}