.trending-content {
  background-color: #ecebe5;

  .box {
    //cursor: pointer;
    overflow: hidden;
     @media (max-width: 990px) {
      padding: 0 0.75rem 1.6rem 0.75rem;
    }
  }
  /* Specific styles for cards */
  .box-link {
    text-decoration: none;
    display: block;
    height: 90%;  
  }
  .box-content {
    height: 100%;
    margin-bottom: 3rem;
    scale:1;
    transition: scale ease-in 1.5s;
    justify-content: space-between;

    .img-box {
      overflow: hidden;
    }
    .detail-cnt{
      justify-content: center;
      min-height: 80px;
      
    }
  }
}
