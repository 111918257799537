:root {
    //--main-font: ;
    --red-color: #E34839;
    --red-fire-color: #AA2213;
    --sky-blue-color: #89B9F5;
    --blue-color: #192F41;
    --dark-gray-color: #1E1E1E;
    --green-color: #D0E157;
    --antique-white-color: #ECEBE5;
    --white-color: #EDEDF3;
    --fw-bold: 600;
    --fw-black: 900;
    --fs-italic: italic;
}