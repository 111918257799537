.top-nav {
    padding: 2.4rem 0!important;
    .navbar-toggler {
        border: none;
        .navbar-toggler-icon {
            background-image: url("../images/icon_menu.svg");
            width: 24px;
            height: 24px;
        }
        .navbar_toggler_icon_closed {
            background-image: url("../images/close-menu-icon.svg");
            width: 24px;
            height: 24px;
        }
        .show-logo {
            display: block;
            -webkit-animation: fadeIn 1s;
            animation: fadeIn 1s;
        }
        .hide-logo {
            display: none;
        }
    }
    
    .link {
        font-size: 1.8rem;
        margin-right: 2rem;
        color: var(--antique-white-color);
        text-decoration: none;
        img {
            @include breakpoint("m-md") {
                margin-right: 21px;
            }
        }
        &:last-child {
            margin-right: 0px;
        }
    }
    button {
        background-color: transparent;
        border: none;
    }
}
.logo {
    width: 225px;
    @include breakpoint("md") {
        width: 111px;
    }
}

@include breakpoint("md") {
    .navbar-collapse {
        padding: 0 45px;
        .navbar-nav {
            gap: 20px;
            margin: 30px 0 6px 0;
        }
        img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            margin-left: -6px;
        }
    }
}

@include breakpoint("md") {
    .cart{
            width: 24px;
            height: 24px;
            margin-right: 16px;
    }
}