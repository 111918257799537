/* Modal */
.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 11;

  .modal-cnt {
    display: flex;
    width: 700px;
    padding-bottom: 32px;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    border-radius: 4px;
    background-color: #ecebe5;
    position: absolute;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
  }
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .underlined-link {
    font-family: "nexa-heavy";
    /* font-weight: 700; */
    font-size: 16px;
    line-height: clamp(2.4rem, 3vw, 3.6rem);
  }

  /* p {
    font-weight: 700;
  } */
  .red-spam {
    color: var(--PR_Dark-Blue, #aa2213);
    font-weight: 950;
    font-family: "nexa-heavy";
  }

  .content-box {
    border: 5px dashed var(--PR_light-blue, #89b9f5);
    background: #fff;
    display: flex;
    padding: 24px 20px;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    @media (max-width: 500px) {
      padding: 14px 10px;
    }
  }

  .p-box-firts {
    color: var(--red-fire-color);
    text-align: center;
    font-family: "nexa-black";
    font-size: 69px;
    /* font-weight: 950; */
    line-height: 70px;
    letter-spacing: -0.9px;
    @media (max-width: 990px) {
      font-size: clamp(3.5rem, 5vw, 6.9rem);
    }
  }
  .p-box-second {
    color: var(--red-fire-color);
    text-align: center;
    font-size: 31px;
    /* font-weight: 950; */
    line-height: 32px;
    align-self: stretch;
    font-family: "nexa-black";
    @media (max-width: 760px) {
      font-size: clamp(1.6rem, 5vw, 2.1rem);
    }
  }

  .close-modal {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    span {
      cursor: pointer;
      margin: 2rem;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 1rem 2rem;
  }

  .button-blue {
    width: 169px;
  }

  .btn-container {
    display: flex;
    padding: 2rem 2rem;
    @media (max-width: 990px) {
      padding: 1rem 2rem;
    }
  }

  .btn-container {
    .firstbutton {
      width: 100%;
      margin: 0.5rem;
      padding: 16px 0;
      border: none;
      border: 1px solid #411b57;
      background-color: red;
    }
  }

  .bold {
    /* font-weight: 400; */
    font-size: 16px;
  }

  @media screen and (max-width: 550px) {
    .modal-cnt {
      flex-direction: column;
      top: 50% !important;
      width: 312px;
      max-width: 510px;
    }

    .heading {
      margin: 1rem;
    }
  }

  @media screen and (max-width: 280px) {
    .modal-cnt {
      flex-direction: column;
      top: 50% !important;
      width: 240px;
      max-width: 510px;
    }
  } 

  //Second modal
  .searchInputModalPopup {
    width: 373px;
    padding: 0px 36px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    @media (max-width: 360px) {
      width: 360px;
    }
    @media (max-width: 290px) {
      width: 300px;
    }

    input {
      border: 1px solid #d0cfca;
      background: #fff;
      border-radius: 60px 60px 60px 60px;
      font-size: 14px;
      outline: none;
      padding: 18px 15px;
      width: 100%;
      color: #0009;
      min-width: 220px;
      font-family: "nexa-light";
      /* font-weight: 400; */
      text-align: center;
      @media (max-width: 290px) {
        font-size: 13px;
      }
    }
  }
  .firts-title {
    color: var(--red-fire-color);
    /* font-weight: 950; */
    font-size: clamp(5.2rem, 10vw, 6.3rem);
    line-height: clamp(4.2rem, 1rem + 7vw, 5rem);
    font-family: "nexa-heavy";
  }

  .second-title {
    color: var(--red-fire-color);
    /* font-weight: 950; */
    font-size: clamp(3.2rem, 10vw, 4.9rem);
    line-height: clamp(4.2rem, 1rem + 7vw, 5rem);
    font-family: "nexa-heavy";
  }

  //third modal
  .refer-firts-title {
    color: var(--blue-color);
   /*  font-weight: 950; */
    font-size: clamp(3.2rem, 10vw, 4.2rem);
    line-height: clamp(3.2rem, 1rem + 7vw, 5rem);
    font-family: "nexa-heavy";
  }

  .refer-second-title {
    color: var(--red-fire-color);
    /* font-weight: 950; */
    font-size: clamp(4.2rem, 10vw, 6.3rem);
    line-height: clamp(4.2rem, 1rem + 7vw, 6.4rem);
    font-family: "nexa-heavy";
    @media screen and (max-width: 280px) {
      font-size: 20px;
      font-size: 30px;
      line-height: 30px;
    }
  }

  .refer-sub-title {
    font-size: 40px;
    @media screen and (max-width: 525px) {
      font-size: 30px;
    }
    @media screen and (max-width: 280px) {
      font-size: 20px;
    }
  }

  //fourth modal
  .p-exit-content {
    padding: 0 5rem 0 5rem;
    @media screen and (max-width: 525px) {
      padding: 0px;
    }
  }
  .img-cnt {
    display: flex;
    justify-content: center;
    width: 100%;
    span {
      cursor: pointer;
      margin: 2rem;
    }
  }
}
